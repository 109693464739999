$social-colors: (
  "at": #ff3333,
  "facebook": #1877F2,
  "whatsapp": #25D366,
  "instagram": #C13584,
  "google-play" : #4285F4,
  "github": #14171A,
  "youtube": #FF0000
);

:root {

  @each $brand,
  $color in $social-colors {
    --#{$brand}: #{$color};
  }
}

@mixin define-social-color($name) {
  .#{$name} {
    --social: var(--#{$name});
  }
}

@each $color,
$_ in $social-colors {
  @include define-social-color(#{$color});
}